html{
	scroll-padding-top:  3rem;
}

body{
	/*font-family: 'Noto Sans', sans-serif;/
	font-family: 'Open Sans', sans-serif;/*/
	font-family: 'Quicksand', sans-serif;
	background-color: #f7f7f7;
	font-size: 0.9rem;
}

.navbar-dark{
	color: #ffffff;
	z-index: 3;
	letter-spacing: 2px;
}


/* SIDE MENU */
.sidenav {
	height: 100%;
	width: 17.5rem;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #2b2b2b;
	border-right: 2px solid #242424;
	overflow-x: hidden;
	padding-top: 1.5rem;
	transition: 0.5s;
	color: #ffffff;
}

.sidenav hr{
	border-color: #ffffff;
	opacity: 0.2;
	}

.sidenav a {
	padding: 0.5rem 2rem;
	text-decoration: none;
	font-size: 0.9rem;
	color: #ffffff;
	display: block;
	transition: 0.3s;
	margin-top: 0.25rem;
}

.sidenav a:hover, .sidenav a.active:hover {
	color: #12446e;
	background-color: #ffffff;
	font-weight: bold;
}

.sidenav a.active {
	font-weight: bold;
	background-color: #3664c7;
	color: #ffffff;
}


.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}

.title{
	background-color: #ffffff;
}

.main {
	transition: margin-left .5s;
	margin-left: 17.5rem;
	background-color: #f7f7f7;
	position: relative;
	height: 100%;
}

@media screen and (max-height: 450px) {
	.sidenav {padding-top: 15px;}
	.sidenav a {font-size: 18px;}
}
/* END SIDE MENU */

hr{
	opacity: 0.1;
}


.loading-box{
	position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,0.6);
}

.spinner-border{
	height: 4rem;
	width: 4rem;
}

.loading-mini-box{
	border-radius: 1rem;
	background: rgba(230, 230, 230,1);
}

.logo-serv{
	height: 1.7rem;
	vertical-align: middle;
}

.card-header h5{
	letter-spacing: 1px;
}

h5{
	color: #676a6c;
}

.card-header{
	background-color: #ffffff;
	border-color: #e7eaec;
}

.card{
	border-radius: 0.1rem;
	border-color: #e7eaec;
}

a, .icon{
	color: #676a6c;
}

.btn-light-2{
	background-color: #f2f2f2;
	border: 1px solid #cccccc;
}

.btn-light-3{
	background-color: #efffed;
	border: 1px solid #2adb14;
}

.btn-light-3:hover{
	background-color: #e2ffde;
	border: 1px solid #cccccc;
}

.btn-primary{
	background-color: #3664c7;
}

.expired .card-header{
	background-color: #ffc7c7;
	border-radius: 0;
}

.expired h5{
	color: #606265;
}

.expired{
	border-color: #ff9191;
	border-radius: 0px;
}

.not-expired .card-header{
	background-color: #c7d9ff;
	border-radius: 0;
}

.not-expired{
	border-color: #91bfff;
}

.close{
	background-color: #000000;
}

.col-12 + .active{
	display: none;
}

.text-small h3{
	font-size: 0.95rem;
}

.full-height{
	min-height: calc(100vh - 4rem);
}

@keyframes append-animate {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 25rem;
		opacity: 1;	
	}
}

@keyframes appear-animate {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;	
	}
}

/* animate new box */
.new-box {
	max-height: 35rem;
	animation: append-animate 0.5s linear;
}

.appear {
	animation: appear-animate 1s linear;
}

.delete-box{
    max-height: 0px;
    transition: 0.2s;
}

.modal-body {
overflow:hidden;
  transition:transform 19s ease-out; 
  height:auto;
  transform:scaleY(1);
  transform-origin:top;
}

.modal-body{
	overflow: visible;
}

.suspended{
	background-color: #ffffff;
}

.suspended img{
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.data-table-extensions{
	background-color: #ffffff;
	border: 1px solid #cccccc;
	margin-top: 2rem;
}

@media only screen and (max-width: 1024px){
	.sidenav{
		height: auto;
		width: 100%;
		position: relative;
		padding-top: 0;
	}

	.main {
		margin-left: 0;
	}

}

.border-left-red{
	border-left: 4px solid #9c3e4b;
	border-right: 1px solid #9c3e4b;
	border-bottom: 1px solid #9c3e4b;
	border-top: 1px solid #9c3e4b;
}

.border-left-blue{
	border-left: 4px solid #3e609c;
	border-right: 1px solid #3e609c;
	border-bottom: 1px solid #3e609c;
	border-top: 1px solid #3e609c;
}

.border-left-green{
	border-left: 4px solid #3e9c44;
	border-right: 1px solid #3e9c44;
	border-bottom: 1px solid #3e9c44;
	border-top: 1px solid #3e9c44;
}

.header-red{
	background-color: #f7e6e6;
}

.header-blue{
	background-color: #e6e9f7;
}

.header-green{
	background-color: #e6f7eb;
}

.header-red h5{
	color: #9c3e4b !important;
}

.header-green h5{
	color: #3e9c44 !important;
}

.header-blue h5{
	color: #3e609c !important;
}


.no-radius{
	border-radius: 0 !important;
}

.b-anim:hover{
	border-color: #010101;
	cursor: pointer;
}